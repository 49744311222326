<template>
  <div>
    <b-modal
      id="modal-tags"
      cancel-variant="outline-secondary"
      :ok-title="$t('Save') "
      :cancel-title="$t('Close')"
      centered
      title="Tag Form"
    >
      <b-form>
        <b-form-group>
          <label for="Name">{{ $t('Name') }}:</label>
          <b-form-input
            id="Name"
            type="text"
            placeholder="Name"
            v-model="tag.name"
          />
        </b-form-group>
        <b-form-group>
          <label for="Color">{{ $t('Color') }}</label>
          <input type="color" class="color-picker" v-model="tag.color" />
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="md"
            @click="changeTags()"
          >
             {{ $t('Save') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 d-flex pb-3">
          <b-button
            class="btn btn-md btn-app mr-2"
            style="
              background-color: #9600ff !important;
              color: #fff;
              border: none;
              float: right;
            "
            v-b-modal.modal-tags
            @click="typeTags(1)"
            > {{ $t('Add_tag') }}</b-button
          >
        </div>
      </div>
      <div class="row equal">
        <div
          class="col-xs-12 col-sm-4 col-md-3 d-flex pb-3"
          v-for="tags in tagsList"
          :key="tags._id"
        >
          <div class="card w-100">
            <div class="card-body" :style="colorTags(tags.color)">
              <div class="d-flex justify-content-between"></div>
            </div>
            <h5 class="card-title flex-nowrap text-center mt-tags">
              {{ tags.name }}
            </h5>
            <div class="card-footer bg-transparent btn-tags">
              <b-button
                class="btn btn-md btn-app mr-2"
                style="
                  background-color: #9600ff !important;
                  color: #fff;
                  border: none;
                "
                v-b-modal.modal-tags
                @click="updateTags(tags), typeTags(2)"
                >  {{ $t('Update') }}</b-button
              >
              <b-button
                class="btn btn-md btn-danger"
                @click="handleArchiveTaskBoard(tags)"
              >
                  {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormGroup,
  BFormSelect,
  BCardText,
  BModal,
  VBModal,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BCardActions,
    BFormGroup,
    BFormSelect,
    BCardText,
    BModal,
    VBModal,
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    ToastificationContent,
  },
  data() {
    return {
      type: "",
      tag: {
        name: "",
        color: null,
      },

      tagsList: [
      ],
    };
  },
  async created() {
    this.getTags();
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    typeTags(arg) {
      if (arg == 1) {
        this.type = "add";
      } else {
        this.type = "update";
      }
    },
    changeTags() {
      if (this.type == "add") {
        axios
          .post("https://backendapinodejs.timecheckit.com/api/tags", {
            user: JSON.parse(localStorage.getItem("userData"))._id,
            color: this.tag.color,
            name: this.tag.name,
          })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tag added",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getTags();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error updated",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else if (this.type == "update") {
        axios
          .put(
            "https://backendapinodejs.timecheckit.com/api/tags/" + this.tag._id,
            this.tag
          )
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "tag updated",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getTags();
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error updated",
                icon: "EditIcon",
                variant: "danger",
              },
            });
            console.error("There was an error!", error);
          });
      }
    },
    handleArchiveTaskBoard(tag) {
      axios
        .delete("https://backendapinodejs.timecheckit.com/api/tags/" + tag._id)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Tag Deleted",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getTags();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error updated",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.error("There was an error!", error);
        });
    },
    updateTags(obj) {
      this.tag.color = obj.color;
      this.tag.name = obj.name;
      this.tag.user = obj.user._id;
      this.tag._id=obj._id
    },
    getTags() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/tags?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          this.tagsList = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    colorTags(color) {
      if (color) {
        return "background: linear-gradient(to right, " + color + ", #fff 95%)";
      } else {
        return "background: linear-gradient(to right, #fff, #fff 95%)";
      }
    },
  },
};
</script>
<style >
.cards-space-tb {
  margin-top: 3%;
  margin-bottom: 3%;
}
.mt-tags {
  margin-top: 1.45rem;
}
.btn-tags {
  display: flex;
  justify-content: center;
}
.color-picker {
  display: inherit;
  width: 100%;
  height: 50px;
  border: none;
}
</style>